import React from "react"

import Header from "../components/Header"
import NavBar from "../components/Navbar/Navbar"
import Head from "../components/HiringPageComponents/Head/Head"
import Title from "../components/Title"
import LeftText from "../components/AboutPageComponents/LeftText/LeftText"
import RightText from "../components/AboutPageComponents/RightText/RightText"
import Footer from "../components/Footer"

export default function HiringPage() {
  return (
    <div>
      <Header />
      <NavBar />
      <Head />
      <Title title="ABOUT" subTitle="About Us" />
      <LeftText title="How can we help you?" />
      <RightText one="yesImg" two="noImg" />
      <LeftText title="Our strength" />
      <RightText one="noImg" two="yesImg" />
      <Footer />
    </div>
  )
}
